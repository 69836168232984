import { id_ID } from "@faker-js/faker";

export function calculateOrderSubtotal(items) {
  // subtotal = sum of (price * quantity) for each item
  if (!items) {
      return 0;
  }
  let subtotal = 0;
  items.forEach((item) => {
    subtotal += (item.price * 100) * item.quantity;
  });
  return subtotal;
}

export function calculateShippingCost(items) {
  // for each store (item.storeId), get item.storeDistance and * 200
  let stores = {
    // storeId: { id, distance, cost, name }
  }
  let shippingTotal = 0;
  let costPerMile = 300;

  // build a map of storeIds
  items.forEach((item) => {
    if (!stores[item.storeId]) {
      stores[item.storeId] = {
        id: item.storeId,
        distance: item.storeDistance,
        cost: 0,
        name: item.storeName,
        supplierName: item.supplierName
      }
    }
  });

  // calculate shipping cost for each store
  Object.keys(stores).forEach((storeId) => {
    stores[storeId].cost = stores[storeId].distance * costPerMile;
    shippingTotal += stores[storeId].cost;
  });

  return { shippingTotal, stores: Object.values(stores) };
}

export function calculateTax(orderTotal) {
  return orderTotal * 0.09;
}

export function calculateOrderTotal(items) {
    if (!items) {
        return 0;
    }
    let total = calculateOrderSubtotal(items);

    const { shippingTotal } = calculateShippingCost(items);
    total += calculateTax(total);
    total += shippingTotal;

    return parseInt(total);
};